@font-face {
	font-family: "SoraRegular";
	src: local("SoraRegular"), url("./fonts/Sora-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "SoraMedium";
	src: local("SoraMedium"), url("./fonts/Sora-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "SoraBold";
	src: local("SoraBold"), url("./fonts/Sora-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "SoraLight";
	src: local("SoraLight"), url("./fonts/Sora-Light.ttf") format("truetype");
}

@font-face {
	font-family: "InterRegular";
	src: local("InterRegular"),
		url("./fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "InterMedium";
	src: local("InterMedium"), url("./fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "InterBold";
	src: local("InterBold"), url("./fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "InterLight";
	src: local("InterLight"), url("./fonts/Inter-Light.ttf") format("truetype");
}

* {
	position: relative;
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: InterRegular;
	font-size: 0.9rem;
	color: #050911;
	background-color: #fffdf9;
}

a {
	text-decoration: none;
}

a:not(.decorated) {
	color: #050911;
}

textarea {
	resize: none;
}

.test {
	border: 1px solid red;
}

.flex-column {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-row-center {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}

.flex-row-left {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: left !important;
	justify-content: left !important;
}

.flex-row-right {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: flex-end !important;
	justify-content: flex-end !important;
}

.full-height {
	height: 100% !important;
}

.full {
	width: 100% !important;
}

.half {
	width: 50% !important;
}

.fit {
	width: fit-content !important;
}

.ellipsis {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.block {
	display: block !important;
}

.border {
	border: 1px solid rgba(229, 231, 235, 0.5);
}

.border-top {
	border-top: 1px solid rgba(229, 231, 235, 0.5);
}

.border-left {
	border-left: 1px solid rgba(229, 231, 235, 0.5);
}

.border-bottom {
	border-bottom: 1px solid rgba(229, 231, 235, 0.5);
}

.border-right {
	border-right: 1px solid rgba(229, 231, 235, 0.5);
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.full-page {
	min-height: 100vh !important;
}

.text-regular-sora {
	font-family: SoraRegular;
}

.text-medium-sora {
	font-family: SoraMedium;
}

.text-bold-sora {
	font-family: SoraBold;
}

.text-light-sora {
	font-family: SoraLight;
}

.text-regular-inter {
	font-family: InterRegular;
	/* font-weight: normal; */
}

.text-medium-inter {
	font-family: InterMedium;
	/* font-weight: 600; */
}

.text-bold-inter {
	font-family: InterBold !important;
	/* font-weight: 700; */
}

.text-light-inter {
	font-family: InterLight;
}

.text-white {
	color: #ffffff !important;
}

.text-default {
	color: #050911 !important;
}

.text-gold {
	color: #fd9a39 !important;
}

.text-red {
	color: #ff3819 !important;
}

.text-xl {
	font-size: 1.5rem !important;
}

.text-lg {
	font-size: 1.125rem !important;
}

.text-md {
	font-size: 1rem !important;
}

.text-sm {
	font-size: 0.875rem !important;
}

.label-md {
	font-size: 0.75rem !important;
}

.label-sm {
	font-size: 0.563rem !important;
}

.opacity-3 {
	opacity: 0.3 !important;
}

.opacity-5 {
	opacity: 0.5 !important;
}

.opacity-7 {
	opacity: 0.7 !important;
}

.upper {
	text-transform: uppercase;
}

.bg-white {
	background-color: #ffffff;
}

.bg-black {
	background-color: #050911;
}

.rounded {
	border-radius: 8px !important;
}

.hero {
	height: 120vh;
	width: 100%;
}

.hero-bg {
	position: absolute;
	width: 100%;
	z-index: 2;
	/* min-width: 1080px; */
	background: url(assets/hero-bg.png);
	background-size: cover;
	background-position: center -5vh;
	background-repeat: no-repeat;
	height: 160vh;
}

.hero-content {
	position: absolute;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	/* background: url(assets/hero-bg.png);
	background-size: cover;
	background-position: 0 -5vh;
	background-repeat: no-repeat; */
	/* padding-bottom: 18vh; */
	z-index: 4;
}

.rainbow-hero-top {
	position: absolute;
	top: -11vh;
	width: 60%;
	right: 0;
	z-index: 1;
}

.rainbow-hero-bottom {
	position: absolute;
	bottom: -62vh;
	left: 0;
	width: 100%;
	z-index: 1;
}

.hero-logo {
	height: 40px;
	margin-bottom: 25px;
}

.hero-title {
	font-size: 5rem;
	line-height: 5rem;
	margin-bottom: 20px;
}

.hero-text {
	width: 40%;
	margin-bottom: 20px;
}

.get-started-btn {
	border: 2px solid #050911 !important;
	padding: 10px 40px;
	box-shadow: -3px 3px 0px #050911;
}

.get-started-btn:active {
	box-shadow: none !important;
	transform: scale(0.99);
}

.for-creators {
	height: 100vh;
	background: url(assets/dark-bg.svg);
	background-size: cover !important;
	background-position: center !important;
	overflow: hidden;
	padding: 100px 0;
}

.creator-avatars {
	height: 100%;
	right: 100px;
	z-index: 3;
	margin-left: 32vw;
}

.creators-side-bg {
	position: absolute;
	top: 0;
	left: 0;
	height: 50%;
	width: 15vw;
	z-index: 2;
}

.for-creators-content {
	position: absolute;
	top: 50px;
	width: 27vw;
	margin-right: 30vw;
	z-index: 3;
}

.for-creators-title {
	margin-bottom: 20px;
}

.for-creators-desc {
	font-size: 1.125rem;
}

.section-title {
	display: block;
	font-size: 3.2rem;
	line-height: 3.2rem;
}

.features-header {
	padding-top: 150px;
}

.feature-graphics {
	height: 90px;
	margin-top: -30px;
}

.feature {
	margin-bottom: 200px;
}

.features-title-container {
	margin: 0 30px;
}

.features-title {
	margin-bottom: 20px;
	text-align: center;
}

.features-content {
	padding-top: 150px;
	background: url(assets/feature-bg.svg);
	background-size: cover;
	background-position: 0 0;
}

.feature-img {
	width: 45%;
}

.feature-text {
	width: 400px;
	margin: 0 50px;
}

.feature-text.mobile {
	display: none;
}

.feature-title {
	display: block;
	font-size: 2.5rem;
	line-height: 3.3rem;
	margin-bottom: 15px;
}

.feature-desc {
	margin-bottom: 15px;
}

.feature-list-item {
	margin-bottom: 10px;
}

.feature-bullet {
	height: 7px;
	width: 7px;
	border-radius: 2px;
	margin-right: 10px;
}

.about {
	padding: 100px 0;
	background: url(assets/about-bg.svg);
	background-size: cover;
	background-position: 0 0;
	background-color: #f65854;
}

.about-title {
	margin-bottom: 20px;
}

.about-desc {
	margin-bottom: 40px;
}

.about-action {
	border-radius: 25px;
	padding: 50px;
}

.about-action-title {
	font-size: 2.3rem;
	margin-bottom: 10px;
}

.about-action-desc {
	margin-bottom: 25px;
}

.about-btn {
	border: none !important;
	padding: 10px 40px;
	color: #f65854 !important;
}

.faq {
	padding: 100px 0;
}

.faq-title {
	display: block;
	font-size: 2.3rem;
	margin-bottom: 30px;
}

.footer {
	padding: 40px 0;
}

.footer-logo {
	height: 20px;
}

.footer-social-icon {
	height: 13px;
}

.footer-text-mobile {
	display: none;
}

.footer-social-link:not(:last-of-type) {
	margin-right: 15px;
}

.footer-separator {
	height: 1px;
	margin: 20px 0;
}

.subscribe {
	padding: 0 20px;
}

.subscribe-form {
	margin-bottom: 100px;
}

.subscribe-input .form-control {
	height: 50px;
	border-radius: 8px 0 0 8px;
	border-right: none !important;
	border-color: #050911 !important;
	box-shadow: none !important;
}

.subscribe-input button {
	height: 50px;
	width: 120px;
	border-radius: 0 8px 8px 0;
	border: 1px solid #050911;
}

.subscribe-title {
	font-size: 2.2rem;
	margin-bottom: 5px;
}

.subscribe-desc {
	margin-bottom: 30px;
}

@media screen and (min-width: 1600px) {
	.hero-bg {
		background-size: 100% 100%;
	}
}

@media screen and (max-width: 992px) {
	.hero-title {
		font-size: 3.2rem;
		line-height: 3.2rem;
		margin-bottom: 10px;
	}

	.hero-text {
		width: 60%;
	}

	.for-creators .container {
		justify-content: left;
	}

	.creator-avatars {
		position: absolute;
		height: 80%;
		right: 100px;
		z-index: 3;
		right: 0;
	}

	.for-creators-content {
		top: 120px;
		width: 35vw;
	}

	.for-creators-title {
		margin-bottom: 15px;
	}

	.for-creators-desc {
		font-size: 1rem;
	}

	.section-title {
		font-size: 2.4rem;
		line-height: 2.4rem;
	}

	.features-title {
		margin-bottom: 10px;
	}

	.feature-img {
		width: 49%;
	}

	.feature-text {
		width: 40%;
		margin: 0 30px;
	}

	.feature-title {
		font-size: 1.8rem;
		line-height: 2.3rem;
		margin-bottom: 15px;
	}

	.about-title {
		padding: 10px;
		text-align: center;
		line-height: 3.5rem !important;
	}

	.about-desc {
		padding: 0 20px;
	}

	.about-action-title {
		font-size: 2rem;
		margin-bottom: 10px;
	}

	.about-action-desc {
		margin-bottom: 20px;
	}

	.faq {
		padding: 70px 0;
	}

	.faq-title {
		display: block;
		font-size: 1.8rem;
		margin-bottom: 20px;
	}

	.subscribe-title {
		font-size: 1.8rem;
	}

	.subscribe-input .form-control,
	.subscribe-input button {
		height: 45px;
	}
}

@media (max-width: 768px) {
	.hero-logo {
		height: 30px;
		margin-bottom: 25px;
	}

	.hero-title {
		font-size: 2.5rem;
		line-height: 2.5rem;
		margin-bottom: 10px;
	}

	.hero-text {
		width: 100%;
		padding: 10px;
	}

	.for-creators .container {
		flex-direction: column;
		justify-content: center;
	}

	.creator-avatars {
		position: relative;
		height: 80%;
		z-index: 3;
		right: 0;
		margin: 0;
	}

	.for-creators-content {
		position: relative;
		width: 100%;
		margin: 200px 0 30px 0;
		padding: 0 10px;
		top: 0;
	}

	.section-title {
		font-size: 1.85rem;
		/* border: 1px solid red; */
	}

	.for-creators-title {
		margin-bottom: 15px;
		font-size: 2.5rem !important;
		line-height: 2.5rem !important;
	}

	.for-creators-desc {
		font-size: 0.9rem;
	}

	.feature-graphics {
		display: none;
	}

	.features-header {
		padding: 100px 20px 0 20px;
	}

	.features-content {
		padding-top: 180px;
		margin-top: -100px;
		background-position: 0 -100px;
	}

	.feature {
		display: block;
		margin-bottom: 100px;
	}

	.feature-img {
		width: calc(100% - 20px);
		margin-bottom: 20px;
	}

	.feature-img.right {
		margin-left: 20px;
	}

	.feature-text {
		width: 100%;
		margin: 0;
		padding: 0 40px 0 20px;
	}

	.feature-text.desktop {
		display: none;
	}

	.feature-text.mobile {
		display: block;
	}

	.feature-title {
		display: block;
		font-size: 2rem;
		line-height: 2.8rem;
		margin-bottom: 15px;
	}

	.feature-desc {
		margin-bottom: 15px;
	}

	.feature-list-item {
		margin-bottom: 10px;
	}

	.feature-bullet {
		height: 7px;
		width: 7px;
		border-radius: 2px;
		margin-right: 10px;
	}

	.about-action {
		padding: 30px;
	}

	.about-action-title {
		font-size: 1.7rem;
	}

	.faq-title {
		font-size: 2.4rem;
		line-height: 3.7rem;
		padding-right: 80px;
		margin-bottom: 40px;
	}

	.footer-social-container {
		justify-content: center !important;
	}

	.footer-logo {
		margin-bottom: 15px;
		height: 30px;
	}

	.footer-logo-container {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;
		margin-bottom: 15px;
	}

	.footer-text-mobile {
		display: block;
	}

	.footer-text-desktop {
		display: none;
	}

	.footer-social-icon {
		height: 15px;
	}

	.footer-social-link {
		margin: 7px;
	}

	.footer span {
		font-size: 0.75rem !important;
	}

	.footer-bottom-text {
		display: block;
		margin-bottom: 10px !important;
	}

	.footer-bottom-container .col {
		text-align: center;
		justify-content: center !important;
	}
}
